const enUS = {
    public: {
        name: {
            status: "State",
            hello: "Hello",
            login: "Login",
            logout: "Logout",
            username: "Username",
            account: "Account",
            email: "E-mail",
            password: "Password",
            confirm_password: "Confirm Password",
            reset_password: "Reset Password",
            captcha: "Captcha",
            more: "More",
            add: "Add",
            edit: "Edit",
            del: "Delete",
            detail: "Detail",
            yes: "Yes",
            no: "No",
            empty: "Empty",
            are_you_sure_delete: "Are you Sure Want To Delete This Item",
            action_cannot_resumed: "This Action Cannot Be Resumed",
            search: "Search",
            reset: "Reset",
            spread: "Spread",
            retract: "Retract",
            show: "Show",
            no_page: "No Page",
            service: "Service",
            phone_country_code: "Phone Country Code",
            name: "Name",
            phone: "Phone",
            sex: "Sex",
            country: "Coutry",
            address: "Address",
            birthday: "Birthday",
            remark: "Remark",
            personal: "Personal",
            enclosure: "Enclosure",
            logo: "Logo",
            confirm: "Confirm",
            send: "Send",
            export: "Export",
            no_permission: "No Permission",
            select_none: "None",
            no_data: "No Data!",
            punchTheClock: "Punch The Clock",
        },
        language: {
            zhTW: "Chinese (Traditional)",
            enUS: "English",
            zhCN: "Chinese (Simplified)",
        },
        status: {
            0: "Unknown",
            1: "Normal",
            2: "Disable",
            3: "Delete",
        },
        sex: {
            1: "Man",
            2: "Woman",
            3: "Unknown"
        },
        placeholder: {
            please_select: "Please Select",
        },
        table: {
            name: "Name",
            status: "State",
            action: "Action",
            content: "Content",
            created_at: "Create Time",
            image: "Image",
            email: "E-mail",
            order_id: "Order",
            sex: "Sex",
            last_ip: "Last Ip",
            phone_country_code: "Please Select Phone Country Code",
            phone: "Phone",
        },
        field: {
            name: "Name",
            username: "Username",
            content: "Content",
            phone_country_code: "Phone Country Code",
            phone: "Phone",
            email: "E-mail",
            order_id: "Order",
            portrait: "Portrait",
            status: "State",
            image: "Image",
            sex: "Sex",
        },
        validate: {
            required: {
                id: "Lack ID",
                username: "Please Entry User Name",
                password: "Please Entry Password",
                repassword: "Please Repeat password",
                name: "Please Entry Name",
                phone: "Please Entry Phone",
                email: "Please Entry E-mail",
                portrait: "Please Upload Portrait",
            },
            tips: {
                inconsistent_password: "Inconsistent password",
                length_should: "Length Should Be {min} To {max}"
            }
        }
    },
    language: {
        zhTW: "Chinese(Traditional)",
        enUS: "English",
        zhCN: "Chinese (Simplified)"
    },
    nav: {
        name: {
            Dashboard: "Dashboard",
            AnnualLeavePlanManage: "Annual Leave PlanManage",
            AttendanceManage: "AttendanceManage",
            AttendanceLogsManage: "Attendance LogsManage",
            AuthorityManage: "AutorityManage",
            DepartmentManage: "DepartmentManage",
            EmployeeManage: "EmployeeManage",
            Settings: "Settings",
            EmployeeEntryRecordManage: "Employee Entry RecordManage",
            HolidayManage: "HolidayManage",
            LeaveManage: "LeaveManage",
            LeaveTypeManage: "Leave TypeManage",
            SelfLeaveManage: "Leave",
            MpfSettingManage: "Mpf SettingManage",
            OvertimeApplicationManage: "Overtime ApplicationManage",
            SelfOvertimeApplicationManage: "Self Overtime ApplicationManage",
            RemainingVacationManage: "Remaining VacationManage",
            ReportManage: "SalaryManage",
            RoleManage: "PositionManage",
            SalaryInformationManage: "Salary InformationManage",
            SettingsManage: "SettingsManage",
            EmployeeModel: "Employee",
            AttendanceModel: "Attendance",
            ReportModel: "Report",
            RoleModel: "Position",
            SalaryModel: "Salary",
            CifManage: "Company & User Profile  (CIF & On Boarding)",
            PavementManage: "Pavement Management",
            RoboticControManage: "Robotic Control Management",
            RemoteOperationManage: "Remote Operation via 5G network",
            ConeDispenseCollectManage: "Cone Dispense and Collect",
            TunnelWashingManage: "Tunnel Washing",
            PlantsCroppingManage: "Plants Cropping",
            RoadMarkingManage: "Road Marking",
            TestingEquationManage: "Testing Equation / Formula Bank ",
            UtilityManage: "Utility",
            MaterialCategoriesManage: "Material Categories & Central Warehouse",
            MobileDeviceManage: "Mobile Device Management (IoT) ",
            ReportingManage: "Reporting Management ",
            IdentityManage: "Identity & Access Management",
            RoadStudManage: "Road Stud Management",
            NamiResearchedManage: "Nami Researched Data",
            AnalyticsCombinationManage: "Analytics Combination",
            AIBankManage: "A.I. Bank + A.I. Training ",
            TestingMaterialManage: "Testing Material Index",
            TestingMachineManage: "Testing Machine Management",
            LoraWanManage: "LoraWan Data Collection Management ",
        },
        table: {
            order_id: "Order ID",
            url: "Url",
        },
        field: {
            pid: "PID",
            order_id: "Order ID",
            name: "Name",
            url: "Url",
            status: "State",
            icon: "Icon",
        },
    },
    login: {
        validate: {
            required: {
                username: "Please Entry Username",
                email: "Please Entry Email",
                password: "Please Entry Password",
                captcha: "Please Entry Captcha",
            }
        }
    },
    annualLeavePlan:{
        name: {
            detailTitle: "AnnualLeavePlan Detail",
            editTitle: "Edit AnnualLeavePlan",
            search: "Search"
        },
        table: {
            id_ann_lea_pla: "ID",
            plan_name: "Plan Name",
            annual_leave_days: "Annual Leave",
            maternity_leave_days: "Maternity Leave",
            paternity_leave_days: "Paternity Leave",
            annual_leave_days_year_imcrement: "Annual Leave Days Year Imcrement",
            max_annual_leave_days: "Max Annual Leave Days",
        },
        field: {
            id_ann_lea_pla: "ID",
            plan_name: "Plan Name",
            annual_leave_days: "Annual Leave",
            maternity_leave_days: "Maternity Leave",
            paternity_leave_days: "Paternity Leave",
            annual_leave_days_year_imcrement: "Annual Leave Days Year Imcrement",
            max_annual_leave_days: "Max Annual Leave Days",
        },
        validate: {
            required: {
                id_ann_lea_pla: "ID Required",
                plan_name: "Plan Name Required",
                annual_leave_days: "Annual Leave Required",
                maternity_leave_days: "Maternity Leave Required",
                paternity_leave_days: "Paternity Leave Required",
                annual_leave_days_year_imcrement: "Annual Leave Days Year Imcrement Required",
                max_annual_leave_days: "Max Annual Leave Days Required",
            }
        },
    },
    attendance:{
        name: {
            detailTitle: "Attendance Detail",
            editTitle: "Edit Attendance",
            search: "Search",
            dateRange: "Date Range",
            department: "Department",
            select_all: "All",
            late: "Late",
            earlyLeave: "Early Leave"
        },
        table: {
            id_att: "ID",
            id_emp: "Employee",
            check_in: "Check In",
            check_out: "Check Out",
            late: "Is Late",
            early_leave: "Early Leave",
        },
        field: {
            id_att: "ID",
            id_emp: "Employee",
            check_in: "Check In",
            check_out: "Check Out",
            late: "Is Late",
            early_leave: "Early Leave",
        },
        validate: {
            required: {
                id_att: "ID Required",
                id_emp: "Employee Required",
                check_in: "Check In Required",
                check_out: "Check Out Required",
                late: "Is Late Required",
                early_leave: "Early Leave Required",
            }
        },
    },
    attendanceLogs:{
        name: {
            detailTitle: "AttendanceLogs Detail",
            editTitle: "Edit AttendanceLogs"
        },
        table: {
            id_att_log: "ID",
            id_emp: "Employee",
            check_time: "Check Time",
            verify_mode: "Verify Mode",
        },
        field: {
            id_att_log: "ID",
            id_emp: "Employee",
            check_time: "Check Time",
            verify_mode: "Verify Mode",
        },
        validate: {
            required: {
                id_att_log: "ID Required",
                id_emp: "Employee Required",
                check_time: "Check Time Required",
                verify_mode: "Verify Mode Required",
            }
        },
    },
    authority:{
        name: {
            detailTitle: "Authority Detail",
            editTitle: "Edit Authority"
        },
        table: {
            id_aut: "ID",
            a_id_aut: "Parent Authority",
            name: "Authority",
            resource_name: "Resource Name",
            type: "Type",
        },
        field: {
            id_aut: "ID",
            a_id_aut: "Parent Authority",
            name: "Authority",
            resource_name: "Resource Name",
            type: "Type",
        },
        validate: {
            required: {
                id_aut: "ID Required",
                a_id_aut: "Parent Authority Required",
                name: "Authority Required",
                resource_name: "Resource Name Required",
                type: "Type Required",
            }
        },
    },
    department:{
        name: {
            detailTitle: "Department Detail",
            editTitle: "Edit Department",
            search: "Search"
        },
        table: {
            id_dep: "ID",
            name: "Department",
            approver_id: "Approver",
            approver2_id: "Approver2",
        },
        field: {
            id_dep: "ID",
            name: "Department",
            approver_id: "Approver",
            approver2_id: "Approver2",
        },
        validate: {
            required: {
                id_dep: "ID Required",
                name: "Department Required",
                approver_id: "Approver Required",
                approver2_id: "Approver2 Required",
            }
        },
    },
    employee:{
        name: {
            detailTitle: "Employee Detail",
            editTitle: "Edit Employee",
            quit: "Quit",
            revokeQuit: "Revoke Quit",
            reinstated: "Reinstated",
            search: "Search",
            are_you_sure_quit: "Are you sure quit this employee",
            are_you_sure_revoke_quit: "Are you sure revoke quit this employee",
            are_you_sure_reinstated: "Are you sure reinstated this employee",
            status: "Status",
            status_all: "All",
            status_be_on_the_job: "Be on the job",
            status_quit: "Quit",
            gccsSetUser: "Put User To Device",
            positionList: "Position Record",
            reset_password:"Reset Password"
        },
        table: {
            id_emp: "ID",
            id_rol: "Position",
            id_ann_lea_pla: "Annual Leave Plan",
            account: "Account",
            password: "Password",
            salt: "Salt",
            last_login_time: "Last Login Time",
            register_time: "Register Time",
            login_failure_counter: "Login Failure Counter",
            avatar_url: "Avatar",
            no: "No",
            name_tw: "Name(TW)",
            name_en: "Name (En)",
            id_card: "ID Card",
            mobile_number: "Mobile Number",
            phone_number: "Phone Number",
            email: "Email",
            address: "Address",
            gender: "Gender",
            birthday: "Birthday",
            remark: "Remark",
            status: "Status",
            duty_plan_type: "Duty Plan Type",
            emergency_contact: "Emergency Contact",
            bank_account: "Bank Account",
            nick_name: "Nick Name",
            marital_status: "Marital Status",
            last_password_update_time: "Last Password Update Time",
            enroll_id: "Enroll Id",
        },
        field: {
            id_emp: "ID",
            id_rol: "Position",
            id_ann_lea_pla: "Annual Leave Plan",
            account: "Account",
            password: "Password",
            salt: "Salt",
            last_login_time: "Last Login Time",
            register_time: "Register Time",
            login_failure_counter: "Login Failure Counter",
            avatar_url: "Avatar",
            no: "No",
            name_tw: "Name(TW)",
            name_en: "Name (En)",
            id_card: "ID Card",
            mobile_number: "Mobile Number",
            phone_number: "Phone Number",
            email: "Email",
            address: "Address",
            gender: "Gender",
            birthday: "Birthday",
            remark: "Remark",
            status: "Status",
            duty_plan_type: "Duty Plan Type",
            entry_date: "Entry Date",
            emergency_contact: "Emergency Contact",
            bank_account: "Bank Account",
            nick_name: "Nick Name",
            marital_status: "Marital Status",
            position:"Position",
            start_time:"Start Time",
            last_password_update_time: "Last Password Update Time",
            enroll_id: "Enroll Id",
        },
        validate: {
            required: {
                id_emp: "ID Required",
                id_rol: "Role Required",
                id_ann_lea_pla: "Annual Leave Plan Required",
                account: "Account Required",
                password: "It shall be at least 8 in length and contain at least one letter, one number and one special character",
                salt: "Salt Required",
                last_login_time: "Last Login Time Required",
                register_time: "Register Time Required",
                login_failure_counter: "Login Failure Counter Required",
                avatar_url: "Avatar Required",
                entry_date: "Entry Date Required",
                no: "No Required",
                name_tw: "Name(TW) Required",
                name_en: "Name (En) Required",
                id_card: "ID Card Required",
                mobile_number: "Mobile Number Required",
                phone_number: "Phone Number Required",
                email: "Email Required",
                address: "Address Required",
                gender: "Gender Required",
                birthday: "Birthday Required",
                remark: "Remark Required",
                status: "Status Required",
                duty_plan_type: "Duty Plan Type Required",
                emergency_contact: "Emergency Contact Required",
                bank_account: "Bank Account Required",
                nick_name: "Nick Name Required",
                marital_status: "Marital Status Required",
                last_password_update_time: "Last Password Update Time Required",
                enroll_id: "Enroll Id Required",
            }
        },
    },
    employeeEntryRecord:{
        name: {
            detailTitle: "EmployeeEntryRecord Detail",
            editTitle: "Edit EmployeeEntryRecord",
            search: "Search",
            new_join_report: "New Join Report",
            term_report: "Term Report",
        },
        table: {
            id_emp_ent_rec: "ID",
            id_emp: "Employee",
            entry_date: "Employee Entry Date",
            term_date: "Employee Term Date",
            term_Remark: "Employee Term Remark",
        },
        field: {
            id_emp_ent_rec: "ID",
            id_emp: "Employee",
            entry_date: "Employee Entry Date",
            term_date: "Employee Term Date",
            term_remark: "Employee Term Remark",
        },
        validate: {
            required: {
                id_emp_ent_rec: "ID Required",
                id_emp: "Employee Required",
                entry_date: "Employee Entry Date Required",
                term_date: "Employee Term Date Required",
                term_remark: "Employee Term Remark Required",
            }
        },
    },
    holiday:{
        name: {
            detailTitle: "Holiday Detail",
            editTitle: "Edit Holiday",
            search: "Search"
        },
        table: {
            id_hol: "ID",
            holiday_name: "Holiday Name",
            start_date: "Start Date",
            end_date: "End Date",
            remark: "Remark",
        },
        field: {
            id_hol: "ID",
            holiday_name: "Holiday Name",
            start_date: "Start Date",
            end_date: "End Date",
            remark: "Remark",
        },
        validate: {
            required: {
                id_hol: "ID Required",
                holiday_name: "Holiday Name Required",
                start_date: "Start Date Required",
                end_date: "End Date Required",
                remark: "Remark Required",
            }
        },
    },
    leave:{
        name: {
            detailTitle: "Leave Detail",
            editTitle: "Edit Leave",
            approve: "Approve",
            search: "Search",
            are_you_sure_send: "Are you sure send this leave application",
            not_enough_annual_leave_days: "Not enough annual leave days!",
            not_enough_maternity_leave_days: "Not enough maternity leave days!",
            not_enough_paternity_leave_days: "Not enough paternity leave days!",
            leave_report: "Leave Report",
        },
        table: {
            id_lea: "ID",
            id_emp: "Employee",
            id_lea_typ: "Leave Type",
            leave_type: "Leave Type",
            start_time: "Start Time",
            end_time: "End Time",
            duration: "Duration(Day)",
            reason: "Reason",
            picture_url: "Picture",
            approver_id: "First Approver",
            approve_time: "First Approve Time",
            cc: "CC",
            remark: "Remark",
            status: "Status",
            approver2_id: "Second Approver",
            approve2_time: "Second Approve Time",
            reject_reason: "Reject Reason",
            submit_time: "Submit Time",
            time_frame: "Time Frame",
            time_unit: "Time Unit(Day)",
        },
        field: {
            id_lea: "ID",
            id_emp: "Employee",
            id_lea_typ: "Leave Type",
            leave_type: "Leave Type",
            start_time: "Start Time",
            end_time: "End Time",
            duration: "Duration(Day)",
            reason: "Reason",
            picture_url: "Picture",
            approver_id: "First Approver",
            approve_time: "First Approve Time",
            cc: "CC",
            remark: "Remark",
            status: "Status",
            approver2_id: "Second Approver",
            approve2_time: "Second Approve Time",
            reject_reason: "Reject Reason",
            submit_time: "Submit Time",
            time_frame: "Time Frame",
            time_unit: "Time Unit(Day)",
        },
        validate: {
            required: {
                id_lea: "ID Required",
                id_emp: "Employee Required",
                id_lea_typ: "Leave Type Required",
                leave_type: "Leave Type Required",
                start_time: "Start Time Required",
                end_time: "End Time Required",
                duration: "Duration Required",
                reason: "Reason Required",
                picture_url: "Picture Required",
                approver_id: "First Approver Required",
                approve_time: "First Approve Time Required",
                cc: "CC Required",
                remark: "Remark Required",
                status: "Status Required",
                approver2_id: "Second Approver Required",
                approve2_time: "Second Approve Time Required",
                reject_reason: "Reject Reason Required",
                submit_time: "Submit Time Required",
                time_frame: "Time Frame Required",
                time_unit: "Time Unit Required",
            }
        },
    },
    leaveType:{
        name: {
            detailTitle: "LeaveType Detail",
            editTitle: "Edit LeaveType"
        },
        table: {
            id_lea_typ: "ID",
            leave_type_name: "Name",
            payroll_required: "Payrool Required",
            leave_type_name_en: "Name(EN)",
        },
        field: {
            id_lea_typ: "ID",
            leave_type_name: "Name",
            payroll_required: "Payrool Required",
            leave_type_name_en: "Name(EN)",
        },
        validate: {
            required: {
                id_lea_typ: "ID Required",
                leave_type_name: "Name Required",
                payroll_required: "Payrool Required Required",
                leave_type_name_en: "Name(EN) Required",
            }
        },
    },
    mpfSetting:{
        name: {
            detailTitle: "MpfSetting Detail",
            editTitle: "Edit MpfSetting"
        },
        table: {
            id_mpf_set: "ID",
            min: "Min Amount(Not Include)",
            max: "Max Amount(Include)",
            percentage: "Percentage",
            fix_amount: "Fix Amount",
            caculate_method: "Caculate Method",
        },
        field: {
            id_mpf_set: "ID",
            min: "Min Amount(Not Include)",
            max: "Max Amount(Include)",
            percentage: "Percentage",
            fix_amount: "Fix Amount",
            caculate_method: "Caculate Method",
        },
        validate: {
            required: {
                id_mpf_set: "ID Required",
                min: "Min Amount(Not Include) Required",
                max: "Max Amount(Include) Required",
                percentage: "Percentage Required",
                fix_amount: "Fix Amount Required",
                caculate_method: "Caculate Method",
            }
        },
    },
    overtimeApplication:{
        name: {
            detailTitle: "OvertimeApplication Detail",
            editTitle: "Edit OvertimeApplication",
            search: "Search",
            approve: "Approve",
            historyApplication: "History Application",
            are_you_sure_pass: "Are you sure pass the application"
        },
        table: {
            id_ove_app: "ID",
            id_emp: "Employee",
            start_time: "Start Time",
            end_time: "End Time",
            duration: "Duration(Hour)",
            approver_id: "Approver",
            approve_time: "Approve Time",
            reason: "Reason",
            status:"Status",
            submit_time: "Submit Time",
            time_frame: "Time Frame",
            time_unit: "Time Unit(Hour)",
            status: "Status",
            reject_reason: "Reject Reason",
            approver2_id: "Second Approver",
            approver2_time: "Second Approve Time",
            time_remark: "Time Frame",
        },
        field: {
            id_ove_app: "ID",
            id_emp: "Employee",
            start_time: "Start Time",
            end_time: "End Time",
            duration: "Duration(Hour)",
            approver_id: "Approver",
            approve_time: "Approve Time",
            reason: "Reason",
            submit_time: "Submit Time",
            time_frame: "Time Frame",
            time_unit: "Time Unit(Hour)",
            status: "Status",
            reject_reason: "Reject Reason",
            approver2_id: "Second Approver",
            approver2_time: "Second Approve Time",
            time_remark: "Time Frame",
        },
        validate: {
            required: {
                id_ove_app: "ID Required",
                id_emp: "Employee Required",
                start_time: "Start Time Required",
                end_time: "End Time Required",
                duration: "Duration Required",
                approver_id: "Approver Required",
                approve_time: "Approve Time Required",
                reason: "Reason Required",
                submit_time: "Submit Time Required",
                time_frame: "Time Frame Required",
                time_unit: "Time Unit Required",
                status: "Status Required",
                reject_reason: "Reject Reason Required",
                approver2_id: "Second Approver Required",
                approver2_time: "Second Approve Time Required",
                time_remark: "Time Frame Required",
            }
        },
    },
    remainingVacation:{
        name: {
            detailTitle: "RemainingVacation Detail",
            editTitle: "Edit RemainingVacation",
            search: "Search",
            year: "Year",
            lessThan: "Annual Leave Less Than",
            moreThan: "Annual Leave Lore Than",
            prediction: "Prediction",
        },
        table: {
            id_rem_vac: "ID",
            id_emp: "Employee",
            annual_leave_days: "Annual Leave",
            maternity_leave_days: "Maternity Leave",
            paternity_leave_days: "Paternity Leave",
            remaining_annual_leave_days: "Remaining Annual Leave",
            remaining_maternity_leave_days: "Remaining Maternity Leave",
            remaining_paternity_leave_days: "Remaining Maternity Leave",
            year: "Year",
            remark: "Remark",
            remaining_sick_leave_days: "Remaining Sick Leave",
            vacation_type:"Vacation Type",
            vacation:"Vacation entitlement(As of the end of the holiday)",
            used_vacation:"Holidays used(Approved but not taken)",
            remaining_vacation:"Leave balance(As of the end of the leave)",
            real_annual_leave_days: "Real Annual Leave",
            remaining_birthday_leave_days: "Remaining Birthday Leave",
            total_maternity:"Total Maternity Leave Days",
            total_paternity:"Total Paternity Leave Days",
            total_birthday:"Total Birthday Leave Days",
        },
        field: {
            id_rem_vac: "ID",
            id_emp: "Employee",
            annual_leave_days: "Annual Leave",
            maternity_leave_days: "Maternity Leave",
            paternity_leave_days: "Paternity Leave",
            remaining_annual_leave_days: "Remaining Annual Leave",
            remaining_maternity_leave_days: "Remaining Maternity Leave",
            remaining_paternity_leave_days: "Remaining Maternity Leave",
            year: "Year",
            remark: "Remark",
            remaining_sick_leave_days: "Remaining Sick Leave",
            sick_leave_days: "Sick Leave",
            real_annual_leave_days: "Real Annual Leave",
            remaining_birthday_leave_days: "Remaining Birthday Leave",
        },
        validate: {
            required: {
                id_rem_vac: "ID Required",
                id_emp: "Employee Required",
                annual_leave_days: "Annual Leave Required",
                maternity_leave_days: "Maternity Leave Required",
                paternity_leave_days: "Paternity Leave Required",
                remaining_annual_leave_days: "Remaining Annual Leave Required",
                remaining_maternity_leave_days: "Remaining Maternity Leave Required",
                remaining_paternity_leave_days: "Remaining Maternity Leave Required",
                year: "Year Required",
                remark: "Remark Required",
                remaining_sick_leave_days: "Remaining Sick Leave Required",
                real_annual_leave_days: "Real Annual Leave Required",
                remaining_birthday_leave_days: "Remaining Birthday Leave Required",
            }
        },
    },
    report:{
        name: {
            detailTitle: "Report Detail",
            editTitle: "Edit Report",
            month: "Month",
            month_placeholder: "Select Month",
            generalSalary: "General Salary",
            batchDelete: "Batch Delete",
            totalSalary: "Total Salary",
            totalMpf: "Total Employee Mpf",
            totalRealSalary: "Net Salary",
            search: "Search",
            batchConfirm: "Batch Confirm",
            are_you_sure_batch_delete: "Are you sure batch delete",
            are_you_sure_confirm: "Are you sure confirm",
            are_you_sure_batch_confirm: "Are you sure batch confirm",
            downSlip: "Download Payment Slip",
            down_payroll_report: "Download Payroll Report",
            sendSlip: "Send Payment Slip",
            down_yearly_payroll_report:"Download Yearly Payroll Report"
        },
        table: {
            id_rep: "ID",
            id_emp: "Employee",
            month: "Month",
            base_salary: "Salary",
            allowance: "Allowance",
            car_subsidy: "Car Subsidy",
            house_subsidy: "House Subsidy",
            other_subsidy: "Other Subsidy",
            commission: "Commission",
            overtime_times: "Overtime Times",
            overtime_pay: "Overtime Pay",
            overtime: "Overtime",
            bonus: "Bonus",
            compensatory_leave_time: "Compensatory Leave Time",
            total_use_annual_leave: "Total Used Annual Leave",
            total_annual_leave: "Total Annual Leave",
            use_annual_leave: "Used Annual Leave",
            remark: "Remark",
            leave_time: "Leave Time",
            absence_days: "Absence Days",
            create_time: "Create Time",
            mpf: "Employee MPF",
            other_deductions: "Other deductions",
            total_salary: "Total Salary",
            total_wages: "Total Wages",
            attendance_days: "Attendance Days",
            rest_days: "Rest Days",
            work_hours: "Work Hours",
            late_time: "LateTime",
            late_times: "Late Times",
            serious_late_times: "Serious Late Times",
            serious_late_time: "Serious Late Time",
            absenteeism_times: "Absenteeism Times",
            early_leave_time: "Early Leave Time",
            early_leave_times: "Early Leave Times",
            no_check_in_times: "No Check In Times",
            no_check_out_times: "No Check Out Times",
            status: "Status",
            need_attendance_days: "Need Attendance Days",
            need_mpf_days: "Need Mpf Days",
            mpf_start_date: "MPF Start Date",
            join_date: "Join Date",
            pay_date: "Pay Date",
            employer_mpf: "Employer MPF",
        },
        field: {
            id_rep: "ID",
            id_emp: "Employee",
            month: "Month",
            base_salary: "Salary",
            allowance: "Allowance",
            car_subsidy: "Car Subsidy",
            house_subsidy: "House Subsidy",
            other_subsidy: "Other Subsidy",
            commission: "Commission",
            overtime_times: "Overtime Times",
            overtime_pay: "Overtime Pay",
            overtime: "Overtime",
            bonus: "Bonus",
            compensatory_leave_time: "Compensatory Leave Time",
            total_use_annual_leave: "Total Used Annual Leave",
            total_annual_leave: "Total Annual Leave",
            use_annual_leave: "Used Annual Leave",
            remark: "Remark",
            leave_time: "Leave Time",
            absence_days: "Absence Days",
            create_time: "Create Time",
            mpf: "Employee MPF",
            other_deductions: "Other deductions",
            total_salary: "Total Salary",
            total_wages: "Total Wages",
            attendance_days: "Attendance Days",
            rest_days: "Rest Days",
            work_hours: "Work Hours",
            late_time: "LateTime",
            late_times: "Late Times",
            serious_late_times: "Serious Late Times",
            serious_late_time: "Serious Late Time",
            absenteeism_times: "Absenteeism Times",
            early_leave_time: "Early Leave Time",
            early_leave_times: "Early Leave Times",
            no_check_in_times: "No Check In Times",
            no_check_out_times: "No Check Out Times",
            status: "Status",
            need_attendance_days: "Need Attendance Days",
            need_mpf_days: "Need Mpf Days",
            mpf_start_date: "MPF Start Date",
            employer_mpf: "Employer MPF",
        },
        validate: {
            required: {
                id_rep: "ID Required",
                id_emp: "Employee Required",
                month: "Please Select Month!",
                base_salary: "Salary Required",
                allowance: "Allowance Required",
                car_subsidy: "Car Subsidy Required",
                house_subsidy: "House Subsidy Required",
                other_subsidy: "Other Subsidy Required",
                commission: "Commission Required",
                overtime_times: "Overtime Times Required",
                overtime_pay: "Overtime Pay Required",
                overtime: "Overtime Required",
                bonus: "Bonus Required",
                compensatory_leave_time: "Compensatory Leave Time Required",
                total_use_annual_leave: "Total Used Annual Leave Required",
                total_annual_leave: "Total Annual Leave Required",
                use_annual_leave: "Used Annual Leave Required",
                remark: "Remark Required",
                leave_time: "Leave Time Required",
                absence_days: "Absence Days Required",
                create_time: "Create Time Required",
                mpf: "MPF Required",
                other_deductions: "Other deductions Required",
                total_salary: "Total Salary Required",
                total_wages: "Total Wages Reuqired",
                attendance_days: "Attendance Days Required",
                rest_days: "Rest Days Required",
                work_hours: "Work Hours Required",
                late_time: "LateTime Required",
                late_times: "Late Times Required",
                serious_late_times: "Serious Late Times Required",
                serious_late_time: "Serious Late Time Required",
                absenteeism_times: "Absenteeism Times Required",
                early_leave_time: "Early Leave Time Required",
                early_leave_times: "Early Leave Times Required",
                no_check_in_times: "No Check In Times Required",
                no_check_out_times: "No Check Out Times Required",
                status: "Status Required",
                need_attendance_days: "Need Attendance Days Required",
                employer_mpf: "Employer MPF Required",
            }
        },
    },
    role:{
        name: {
            detailTitle: "Position Detail",
            editTitle: "Edit Position"
        },
        table: {
            id_rol: "ID",
            id_dep: "Department",
            name: "Position",
            remark: "Remark",
            approver_id: "Approver",
            approver2_id: "Approver2",
        },
        field: {
            id_rol: "ID",
            id_dep: "Department",
            name: "Position",
            remark: "Remark",
            authorityList: "Authroity",
            approver_id: "Approver",
            approver2_id: "Approver2",
        },
        validate: {
            required: {
                id_rol: "ID Required",
                id_dep: "Department Required",
                name: "Position Required",
                remark: "Remark Required",
                authorityList: "Authroity Required",
                approver_id: "Approver Required",
                approver2_id: "Approver2 Required",
            }
        },
    },
    salaryInformation:{
        name: {
            detailTitle: "SalaryInformation Detail",
            editTitle: "Edit SalaryInformation",
            search: "Search"
        },
        table: {
            id_sal_inf: "ID",
            id_emp: "Employee",
            base_salary: "Base Salary",
            allowance: "Allowance",
            car_subsidy: "Car Subsidy",
            house_subsidy: "House Subsidy",
            saturday_overtime_multiples: "Saturday Overtime Multiples",
            sunday_overtime_multiples: "Sunday Overtime Multiples",
            holiday_overtime_multiples: "Holiday Overtime Multiples",
            normal_overtime_multiples: "Normal Overtime Multiples",
            remark: "Remark",
            create_time: "Create Time",
            expire_time: "Expire Time",
            status: "Status",
            commission: "Commission",
            bonus: "Bonus",
        },
        field: {
            id_sal_inf: "ID",
            id_emp: "Employee",
            base_salary: "Base Salary",
            allowance: "Allowance",
            car_subsidy: "Car Subsidy",
            house_subsidy: "House Subsidy",
            saturday_overtime_multiples: "Saturday Overtime Multiples",
            sunday_overtime_multiples: "Sunday Overtime Multiples",
            holiday_overtime_multiples: "Holiday Overtime Multiples",
            normal_overtime_multiples: "Normal Overtime Multiples",
            remark: "Remark",
            create_time: "Create Time",
            expire_time: "Expire Time",
            status: "Status",
            commission: "Commission",
            bonus: "Bonus",
        },
        validate: {
            required: {
                id_sal_inf: "ID Required",
                id_emp: "Employee Required",
                base_salary: "Base Salary Required",
                allowance: "Allowance Required",
                car_subsidy: "Car Subsidy Required",
                house_subsidy: "House Subsidy Required",
                saturday_overtime_multiples: "Saturday Overtime Multiples Required",
                sunday_overtime_multiples: "Sunday Overtime Multiples Required",
                holiday_overtime_multiples: "Holiday Overtime Multiples Required",
                normal_overtime_multiples: "Normal Overtime Multiples Required",
                remark: "Remark Required",
                create_time: "Create Time Required",
                expire_time: "Expire Time Required",
                commission: "Commission Required",
                bonus: "Bonus Required",
            }
        },
    },
    generalSalary:{
        name: {
            title: "General Salary",
            month: "Month",
            month_placeholder: "Select Month",
            generalSalary: "General"
        },
        table: {
            id_rep: "ID",
            id_emp: "Employee",
            month: "Month",
            base_salary: "Salary",
            allowance: "Allowance",
            car_subsidy: "Car Subsidy",
            house_subsidy: "House Subsidy",
            other_subsidy: "Other Subsidy",
            commission: "Commission",
            overtime_times: "Overtime Times",
            overtime_pay: "Overtime Pay",
            overtime: "Overtime(Minute)",
            bonus: "Bonus",
            compensatory_leave_time: "Compensatory Leave Time",
            total_use_annual_leave: "Total Used Annual Leave",
            total_annual_leave: "Total Annual Leave",
            use_annual_leave: "Used Annual Leave",
            remark: "Remark",
            leave_time: "Leave Time",
            absence_days: "Absence Days",
            create_time: "Create Time",
            mpf: "MPF",
            other_deductions: "Other deductions",
            total_salary: "Total Salary",
            attendance_days: "Attendance Days",
            rest_days: "Rest Days",
            work_hours: "Work Hours",
            late_time: "LateTime",
            late_times: "Late Times",
            serious_late_times: "Serious Late Times",
            serious_late_time: "Serious Late Time",
            absenteeism_times: "Absenteeism Times",
            early_leave_time: "Early Leave Time",
            early_leave_times: "Early Leave Times",
            no_check_in_times: "No Check In Times",
            no_check_out_times: "No Check Out Times",
        },
        field: {
            id_rep: "ID",
            id_emp: "Employee",
            month: "Month",
            base_salary: "Salary",
            allowance: "Allowance",
            car_subsidy: "Car Subsidy",
            house_subsidy: "House Subsidy",
            other_subsidy: "Other Subsidy",
            commission: "Commission",
            overtime_times: "Overtime Times",
            overtime_pay: "Overtime Pay",
            overtime: "Overtime(Minute)",
            bonus: "Bonus",
            compensatory_leave_time: "Compensatory Leave Time",
            total_use_annual_leave: "Total Used Annual Leave",
            total_annual_leave: "Total Annual Leave",
            use_annual_leave: "Used Annual Leave",
            remark: "Remark",
            leave_time: "Leave Time",
            absence_days: "Absence Days",
            create_time: "Create Time",
            mpf: "MPF",
            other_deductions: "Other deductions",
            total_salary: "Total Salary",
            attendance_days: "Attendance Days",
            rest_days: "Rest Days",
            work_hours: "Work Hours",
            late_time: "LateTime",
            late_times: "Late Times",
            serious_late_times: "Serious Late Times",
            serious_late_time: "Serious Late Time",
            absenteeism_times: "Absenteeism Times",
            early_leave_time: "Early Leave Time",
            early_leave_times: "Early Leave Times",
            no_check_in_times: "No Check In Times",
            no_check_out_times: "No Check Out Times",
        },
        validate: {
            required: {
                id_rep: "ID Required",
                id_emp: "Employee Required",
                month: "Month Required",
                base_salary: "Salary Required",
                allowance: "Allowance Required",
                car_subsidy: "Car Subsidy Required",
                house_subsidy: "House Subsidy Required",
                other_subsidy: "Other Subsidy Required",
                commission: "Commission Required",
                overtime_times: "Overtime Times Required",
                overtime_pay: "Overtime Pay Required",
                overtime: "Overtime Required",
                bonus: "Bonus Required",
                compensatory_leave_time: "Compensatory Leave Time Required",
                total_use_annual_leave: "Total Used Annual Leave Required",
                total_annual_leave: "Total Annual Leave Required",
                use_annual_leave: "Used Annual Leave Required",
                remark: "Remark Required",
                leave_time: "Leave Time Required",
                absence_days: "Absence Days Required",
                create_time: "Create Time Required",
                mpf: "MPF Required",
                other_deductions: "Other deductions Required",
                total_salary: "Total Salary Required",
                attendance_days: "Attendance Days Required",
                rest_days: "Rest Days Required",
                work_hours: "Work Hours Required",
                late_time: "LateTime Required",
                late_times: "Late Times Required",
                serious_late_times: "Serious Late Times Required",
                serious_late_time: "Serious Late Time Required",
                absenteeism_times: "Absenteeism Times Required",
                early_leave_time: "Early Leave Time Required",
                early_leave_times: "Early Leave Times Required",
                no_check_in_times: "No Check In Times Required",
                no_check_out_times: "No Check Out Times Required",
            }
        },
    },
    leaveApprove:{
        name: {
            title: "Leave Approve",
            employeeInfo: "Employee Info",
            remainingVacation: "Remaining Vacation",
            historyLeave: "History Leave",
            pass: "Pass",
            reject: "Reject",
            are_you_sure_pass: "Are you sure pass the leave application",
            reject_title: "Leave Reject"
        },
        table: {
            
        },
        field: {
            
        },
        validate: {
            required: {
                
            }
        },
    },
    overtimeApplicationApprove:{
        name: {
            title: "Overtime Application Approve",
            overtimeApplication: "Overtime Application Info",
            historyApplication: "History Application",
            reject_title: "Reject Overtime Application"
        },
        table: {
            
        },
        field: {
            
        },
        validate: {
            required: {
                
            }
        },
    },
    gccsSetUser:{
        name: {
            title: "Put User Into Device",
        },
        table: {
            
        },
        field: {
            device: "Device",
            user_privilege: "User Privilege",
        },
        validate: {
            required: {
                device: "Please Select Device",
                user_privilege: "Please Select User Privilege",
            }
        },
    },
    downloadLeaveReport:{
        name: {
            title: "Leave Report",
        },
        table: {
            
        },
        field: {
            
        },
        validate: {
            required: {
                
            }
        },
    },
    newJoinReport:{
        name: {
            title: "New Join Report",
        },
        table: {
            
        },
        field: {
            
        },
        validate: {
            required: {
                
            }
        },
    },
    termReport:{
        name: {
            title: "Term Report",
        },
        table: {
            
        },
        field: {
            
        },
        validate: {
            required: {
                
            }
        },
    },
    reportConfirm:{
        name: {
            title: "Confirm",
        },
        table: {
            
        },
        field: {
            pay_date:"Pay Date"
        },
        validate: {
            required: {
                pay_date: "Please Select Pay Date",
            }
        },
    },
    settings:{
        name: {
            detailTitle: "Settings Detail",
            editTitle: "Edit Settings"
        },
        table: {
            id_set: "ID",
            name: "Name",
            content: "Content",
        },
        field: {
            id_set: "ID",
            name: "Name",
            content: "Content",
        },
        validate: {
            required: {
                id_set: "ID Required",
                name: "Name Required",
                content: "Content Required",
            }
        },
    },
    config:{
        name: {
            email_setting: "Email Setting",
        },
        table: {
        },
        field: {
            host: "Host",
            username: "Username",
            password: "Password",
            sender: "Sender",
        },
        validate: {
            required: {
                host: "Host Required",
                username: "Username Required",
                password: "Password Required",
                sender: "Sender Required",
            }
        },
    },
    roleLogs:{
        name: {
            detailTitle: "RoleLogs Detail",
            editTitle: "Edit RoleLogs"
        },
        table: {
            id_rol_log: "ID",
            id_emp: "Employee",
            create_time: "Create Time",
            position_name: "Position",
        },
        field: {
            id_rol_log: "ID",
            id_emp: "Employee",
            create_time: "Create Time",
            position_name: "Position",
        },
        validate: {
            required: {
                id_rol_log: "ID Required",
                id_emp: "Employee Required",
                create_time: "Create Time Required",
                position_name: "Position Required",
            }
        },
    },
    prediction:{
      name: {
          title: "Prediction",
          date: "Date"
      },
      table: {
          
      },
      field: {
          
      },
      validate: {
          required: {
              date: "Date Required",
          }
      },
    },
}
export default enUS